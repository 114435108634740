import { defaultLocale, locales } from './locale-data'

/**
 * Gets the path prefix for the specified locale. The default locale is omitted,
 * i.e. `/` and other locales are returned as `/locale/`.
 * @func
 * @param {String} locale requested locale
 * @returns {String} current locale
 */
export const getLocalePathPrefix = (locale) => {
  if (locale === defaultLocale) {
    return '/'
  }

  return `/${locale}/`
}

/**
 * Get current locale from path.
 * @func
 * @param {String} path browser path
 * @returns {String} current locale
 */
export const getCurrentLocale = (path) => {
  const locale = path.split('/')[1]
  return sanitizeLocale(locale)
}

/**
 * Returns a valid locale from configured locales or falls back to defaultLocale
 * @func
 * @param {String} requestedLocale requested locale
 * @returns {String} sanitized locale
 */
export const sanitizeLocale = (requestedLocale) => {
  if (!requestedLocale) {
    return defaultLocale
  }

  const validLocale = locales.find((locale) => locale.identifier === requestedLocale)

  return validLocale ? validLocale.identifier : defaultLocale
}

/**
 * Returns an array of path metadata for constructing a locale menu.
 * @param {String} currentLocale current locale
 * @param {Array} translatedPages array of translated pages { locale, path }
 * @returns {Array} locale menu data
 */
export const getAllLocalePathsForMenu = (currentLocale, translatedPages) => {
  return locales.map((locale) => ({
    identifier: locale.identifier,
    localizedName: locale.localizedName,
    selected: currentLocale === locale.identifier,
    link: getPathForLocale(locale.identifier, translatedPages),
  }))
}

/**
 * Gets the current path in specified locale or falls back to the homepage for that locale,
 * unless the locale isn't valid in which case it returns the default locale homepage.
 * @param {String} requestedLocale locale requested
 * @param {Array} translatedPages array of translated pages { locale, path }
 * @returns {String} new path
 */
export const getPathForLocale = (requestedLocale, translatedPages) => {
  if (!locales.find((locale) => locale.identifier === requestedLocale)) {
    return getLocalePathPrefix(defaultLocale)
  }

  const translatedPage = translatedPages.find((translatedPage) => translatedPage.locale === requestedLocale)
  if (!translatedPage) {
    return getLocalePathPrefix(requestedLocale)
  }

  return translatedPage.path
}

export const getBaseDomain = () => {
  const siteUrl = process.env.SITE_URL || 'https://www.local.visualcv.com'
  const baseDomain = siteUrl.replace(/^https:\/\/[a-z]+\./, '')

  return baseDomain
}

/**
 * Creates an app url including locale from a path relative to /
 * @param {string} appPath Relative app path
 * @param {string} locale The current locale
 * @returns {string}
 */
export const createAppUrl = (appPath, locale) => {
  const fullAppUrl = `https://app.${getBaseDomain()}${appPath}`

  if (locale === defaultLocale) {
    return fullAppUrl
  }

  const appPathWithLocale = appendQueryParamsToUrl(fullAppUrl, { locale })

  return appPathWithLocale
}

export const appendQueryParamToRelativeUrl = (baseUrl, param, value) => {
  const relativeFake = 'https://relative'
  const url = new URL(baseUrl, relativeFake)
  url.searchParams.append(param, value)
  return url.toString().replace(relativeFake, '')
}

export const appendQueryParamsToUrl = (url, params) => {
  const formattedURL = new URL(url)
  const paramKeys = Object.keys(params)
  paramKeys.forEach((key) => {
    formattedURL.searchParams.append(key, params[key])
  })
  return formattedURL.toString()
}

/**
 * Checks if an object is a ContentfulLink
 * @param {*} link
 * @returns {boolean}
 */
export const isContentfulLink = (link) => !!(link?.url?.url || link?.url?.fallback?.url)

/**
 * Checks if an object is a ContentfulUrl
 * @param {*} url
 * @returns {boolean}
 */
export const isContentfulUrl = (url) => !!(url?.url || url?.fallback?.url)

/**
 * @param {object|string} url
 * @param {string} locale
 * @returns {string}
 */
export const createUrl = (url, locale) => {
  let useFallback = false
  let urlValue = url

  if (isContentfulUrl(url)) {
    useFallback = !url.url
    const validUrl = useFallback ? url.fallback : url

    if (validUrl.app_url) {
      return createAppUrl(validUrl.url, locale)
    }

    urlValue = validUrl.url
  }

  if (!isGatsbyRouterUrl(urlValue)) {
    return urlValue
  }

  // When using fallback, it's implicitly an English URL
  if (useFallback || locale === defaultLocale) {
    return urlPathJoin(urlValue)
  }

  return urlPathJoin(getLocalePathPrefix(locale), urlValue)
}

/**
 * Checks if a string represents a URL starting with http://, https:// or ftp://
 * @param {string} url
 * @returns {boolean}
 */
export const isExternalUrl = (url) => {
  const regex = /^((http[s]?|ftp):)?\/\//
  return regex.test(url)
}

export const isLegacyAppUrl = (url) => url.startsWith('/app/')

export const isGatsbyRouterUrl = (url) => !isLegacyAppUrl(url) && !isExternalUrl(url) && !isFragmentUrl(url)

export const isRelativeUrl = (url) => /^\/(?!\/)/.test(url)

/**
 * Checks if a string represents url fragment
 * @param {string} url
 * @returns {boolean}
 */
export const isFragmentUrl = (url) => /^#/.test(url)

/**
 * @param  {...string} urlParts
 * @returns {string}
 */
export const urlPathJoin = (...urlParts) => {
  let joinedUrl = '/'

  const sanitizedParts = urlParts
    .map((urlPart) => {
      // We have had some paths that have been emitted as /null/. This is always wrong, so throw.
      if (urlPart == null) {
        throw new Error('Attempted to urlPathJoin with a null or undefined urlPart.')
      }

      // We don't want to allow just anything to be coerced into a url path by accident. It might
      // be valid, but I'd rather throw and force an explicit conversion than allow possible errors.
      if (!(typeof urlPart === 'number' || typeof urlPart === 'string')) {
        console.error(typeof urlPart)
        throw new Error("Attempted to urlPathJoin with a value that wasn't a simple string or number")
      }

      // Coerce the input into a string and trim
      urlPart = `${urlPart}`.trim()

      if (urlPart.length === 0) {
        throw new Error('Attempted to urlPathJoin with a value that was only whitespace.')
      }

      // Trim leading and trailing slashes
      if (urlPart.charAt(0) === '/') {
        urlPart = urlPart.slice(1)
      }

      if (urlPart.length > 0 && urlPart.charAt(urlPart.length - 1) === '/') {
        urlPart = urlPart.slice(0, urlPart.length - 1)
      }

      // Trim the content inside any /'s
      urlPart = urlPart.trim()

      return urlPart
    })
    .filter(Boolean)

  if (sanitizedParts.length) {
    joinedUrl += sanitizedParts.join('/') + '/'
  }

  return joinedUrl
}

export const getLocalizedRootUrlPrefix = (parentPage, locale) =>
  urlPathJoin(getLocalePathPrefix(locale), parentPage.slug)

export const doesPageEnableLocale = (page, locale) => {
  return page.languages?.includes(locale)
}

const capitalizeTitle = (title) =>
  title
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

/**
 * Resume Sample titles were imported into Contentful as all lowercase. We manually capitalize them, but this doesn't
 * work for acronyms like CEO, nor for all languages. So when there are ANY caps in the value from
 * Contentful, we can assume it's been fixed manually.
 * @param {string} title A title which may or may not already be capitalized appropriately
 * @returns {string} Either the original title value if it contained any caps or a capitalized version of it.
 */
export const capitalizeLowercaseTitle = (title) => (title === title.toLowerCase() ? capitalizeTitle(title) : title)

export const formatISODate = (date, locale) =>
  new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(date))

/**
 * Formats a European full name with first and optional last name
 * @param {string} firstName
 * @param {string=} lastName
 * @returns {string}
 */
export const formatFullName = (firstName, lastName) => (lastName ? `${firstName} ${lastName}` : firstName)

export const slugify = (slug) => encodeURIComponent(slug.trim().replace(/\s/g, '-').toLowerCase())

export const localeMatches = (string, searchString) => {
  if (string === undefined || string === null || searchString === undefined || searchString === null) {
    throw new Error('localeMatches requires at least 2 parameters')
  }

  const stringLength = string.length
  const searchStringLength = searchString.length
  const lengthDiff = stringLength - searchStringLength

  const matches = []
  for (let i = 0; i <= lengthDiff; i++) {
    const substring = string.substring(i, i + searchStringLength)
    if (
      substring.localeCompare(searchString, undefined, {
        usage: 'search',
        sensitivity: 'base',
        ignorePunctuation: true,
      }) === 0
    ) {
      matches.push(substring)
    }
  }

  return [...new Set(matches)]
}

export const localeEquals = (string, searchString) =>
  string.localeCompare(searchString, undefined, { usage: 'search', sensitivity: 'base' }) === 0

export const localeIncludes = (string, searchString) => {
  if (string === undefined || string === null || searchString === undefined || searchString === null) {
    throw new Error('localeIncludes requires at least 2 parameters')
  }

  return localeMatches(string, searchString).length > 0
}

export const localeIndexOf = (array, searchString) => {
  if (array === undefined || array === null || searchString === undefined || searchString === null) {
    throw new Error('localeIndexOf requires at least 2 parameters')
  }

  for (let i = 0; i < array.length; i++) {
    if (array[i].localeCompare(searchString, undefined, { usage: 'search', sensitivity: 'base' }) === 0) {
      return i
    }
  }

  return -1
}
