/*
The import order of resources using createGlobalStyle determines the order CSS is added to the global stylesheet
See: https://github.com/styled-components/styled-components/issues/3293#issuecomment-702820606
And the related Styled Components release notes: https://styled-components.com/releases#v5.2.0

Preallocate global style placement to ensure cGS is consistently inserted at the top of the stylesheet;
note that this is done in runtime order so, if you have multiple cGS that have overlapping styles,
ensure they're defined in code in the sequence you would want them injected
(see https://github.com/styled-components/styled-components/pull/3239)
*/
import GlobalFonts from './GlobalFonts'
import TypographyStyles from './TypographyStyles'
import GlobalStyles from './GlobalStyles'

// eslint-disable-next-line import/no-unresolved
import cookies from 'js-cookie'
import { StrictMode } from 'react'
import CookieConsent from '../components/CookieConsent'
import IntlContextProvider from '../IntlContextProvider'
import { defaultLocale } from './locale-data'
import { getCurrentLocale } from './locales'

const WrapPageElement = ({
  element,
  props: {
    location,
    pageContext: { intl },
  },
}) => {
  const { pathname, search } = location

  if (!intl || !intl.locale || !intl.defaultLocale) {
    intl = { locale: getCurrentLocale(pathname), defaultLocale }
  }

  const params = new URLSearchParams(search)
  const utmSource = params.get('utm_source')
  const utmMedium = params.get('utm_medium')

  if (utmSource) {
    // remember utm_source in cookie so that we can store it with user on signup
    cookies.set('utm_source', utmSource, { expires: 7 }) // days
  }

  if (utmMedium) {
    // remember utm_medium in cookie so that we can store it with user on signup
    cookies.set('utm_medium', utmMedium, { expires: 7 }) // days
  }

  return (
    <StrictMode>
      <IntlContextProvider
        intl={intl}
        pathname={pathname}>
        <GlobalFonts locale={intl.locale} />
        <TypographyStyles $locale={intl.locale} />
        <GlobalStyles />
        {element}
        <CookieConsent />
      </IntlContextProvider>
    </StrictMode>
  )
}

export default WrapPageElement
