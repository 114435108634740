import { createGlobalStyle } from 'styled-components'

import {
  InterBoldLatin,
  InterBoldLatinExt,
  InterNormalLatin,
  InterNormalLatinExt,
  InterVariableLatin,
  InterVariableLatinExt,
  InterVariableLatinExtWOFF2,
  InterVariableLatinWOFF2,
} from '../fonts/inter'
import latin from './latin'

const cssFontFaces = createGlobalStyle`
  ${InterNormalLatin}
  ${InterNormalLatinExt}
  ${InterBoldLatin}
  ${InterBoldLatinExt}

  ${InterVariableLatin}
  ${InterVariableLatinExt}
`

const preloadFonts = {
  InterVariableLatin: InterVariableLatinWOFF2,
  InterVariableLatinExt: InterVariableLatinExtWOFF2,
}

export default {
  cssFontFaces,
  bodyFont: latin.bodyFont,
  preloadFonts,
}
