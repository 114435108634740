import { css } from 'styled-components'
import media from './media'

export const heading1Style = css`
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 125% */
  letter-spacing: -0.4px;

  ${media.sm`
    font-size: 48px;
    line-height: 56px; /* 116.667% */
    letter-spacing: -1px;
  `}
`

export const heading2Style = css`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 125% */
  letter-spacing: -0.5px;

  ${media.sm`
    font-size: 40px;
    line-height: 48px; /* 116.667% */
    letter-spacing: -0.6px;
  `}
`

export const heading3Style = css`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 125% */
  letter-spacing: -0.5px;

  ${media.sm`
    font-size: 32px;
    line-height: 40px; /* 116.667% */
    letter-spacing: -0.4px;
  `}
`

export const heading4Style = css`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 125% */
  letter-spacing: -0.5px;

  ${media.sm`
    font-size: 24px;
    line-height: 32px; /* 116.667% */
    letter-spacing: -0.5px;
  `}
`

export const heading5Style = css`
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 125% */
  letter-spacing: -0.5px;

  ${media.sm`
    font-size: 20px;
    line-height: 32px; /* 116.667% */
    letter-spacing: -0.5px;
  `}
`
