import { createGlobalStyle, css } from 'styled-components'

import { InterBoldLatin, InterNormalLatin, InterVariableLatin, InterVariableLatinWOFF2 } from '../fonts/inter'

const cssFontFaces = createGlobalStyle`
  ${InterNormalLatin}
  ${InterBoldLatin}

  ${InterVariableLatin}
`

const bodyFont = css`
  font-family: Inter, sans-serif;

  @supports (font-variation-settings: normal) {
    font-family: 'InterVariable', sans-serif;
    font-synthesis: none;

    i,
    em {
      font-variation-settings: 'slnt' -10;
    }
  }
`

const preloadFonts = {
  InterVariableLatin: InterVariableLatinWOFF2,
}

export default {
  cssFontFaces,
  bodyFont,
  preloadFonts,
}
