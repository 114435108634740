import classNames from 'classnames/dedupe'
import { Link as GatsbyLink } from 'gatsby'
import { useIntl } from '../utils/IntlContext'
import { createUrl, isContentfulLink, isContentfulUrl, isGatsbyRouterUrl, isRelativeUrl } from '../utils/locales'

/**
 * @typedef {object} Props
 * @prop {object|string=} to A URL which represents a relative path, fully-qualified external URL, or a VisualCV App URL
 * @prop {string=} activeClassName A class to apply when this Link is active (only applied to Gatsby Link)
 * @prop {boolean=} partiallyActive Class the link as highlighted if there is a partial match via a the to being prefixed to the current url (only applied to Gatsby Link)
 */

/** A convenience wrapper for Gatsby's Link and DOM <a> as
 * Gatsby's Link can only be used for internal links.
 * @type {React.FC<Props>}
 */
const Link = ({ to, rel, target, activeClassName, partiallyActive, children, ...rest }) => {
  const { locale } = useIntl()

  // Use Gatsby Link for internal links, and <a> for others

  // Since DOM element <a> cannot receive activeClassName
  // and partiallyActive, destructure the prop here and
  // pass it only to GatsbyLink

  let toValue = to
  let relValue = rel
  let targetValue = target

  if (isContentfulLink(to)) {
    toValue = createUrl(to.url, locale)
    relValue = classNames(rel, { nofollow: to.no_follow })
    targetValue = target || (to.open_tab ? '_blank' : undefined)
  } else if (isContentfulUrl(to)) {
    toValue = createUrl(to, locale)
  }

  if (!toValue || !isGatsbyRouterUrl(toValue)) {
    return (
      <a
        data-testid="html-link"
        href={toValue || undefined}
        rel={relValue}
        target={targetValue}
        {...rest}>
        {children}
      </a>
    )
  } else if (isRelativeUrl(toValue)) {
    return (
      <GatsbyLink
        data-testid="gatsby-link"
        to={toValue}
        rel={relValue}
        target={targetValue}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...rest}>
        {children}
      </GatsbyLink>
    )
  }

  throw new Error(
    'Link to must be either an external url (i.e. `https://...`) or a root relative path (i.e. `/path/`, not `path` or `../path`, etc.)',
  )
}

export default Link
