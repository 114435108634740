import { createGlobalStyle, keyframes } from 'styled-components'
import media from './media'

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

export const slideIn = keyframes`
  0% {
    transform: translateX(-20px);
  }

  100% {
    transform: translateX(0);
  }
`

export default createGlobalStyle`
  :root {
    // Theme colors should be functional wherever possible. For example, when
    // styling text, use var(--color-typography-dark) instead of var(--color-black).
    // Where the color is purely presentational, use the color directly.

    // Typography colors used generally
    --color-typography-primary: var(--color-primary);
    --color-typography-dark: #101519;
    --color-typography-medium: #4e687e;
    --color-typography-light: var(--color-white);
    --color-typography-grey: var(--color-grey);
    --color-typography-light-grey: var(--color-light-grey);
    --color-typography-white: var(--color-white);

    // Typography colors used on specific backgrounds
    --color-typography-on-primary: var(--color-typography-white);

    // Marker colors
    --color-bullet: #4e687e;

    // Background colors
    --color-background-primary: var(--color-primary);
    --color-background-lighter-primary: var(--color-lighter-primary);
    --color-background-light: #f4f7f8;
    --color-background-light-2: #f2f5f7; // New light grey bg color
    --color-background-lighter-grey: var(--color-lighter-grey);
    --color-background-white: var(--color-white);
    --color-background-light-blue: var(--color-light-blue);
    --color-background-bluish-purple: var(--color-bluish-purple);
    --color-background-pink: var(--color-pink);
    --color-background-pink-coral: var(--color-pink-coral);
    --color-background-powder-blue: var(--color-powder-blue);
    --color-background-sand: var(--color-sand);
    --color-background-mint: var(--color-mint);
    --color-background-pale-green: #ecfbf7;
    --color-background-blog-light-blue: #d8e9f9;
    --color-background-black: var(--color-black);

    // Color palette
    --color-primary: #1f7ed6; // New and correct - RGB(31, 126, 214)
    --color-primary-50pc: rgba(31, 126, 214, 0.5);
    --color-primary-25pc: rgba(31, 126, 214, 0.25);
    --color-primary-20pc: rgba(31, 126, 214, 0.2);
    --color-primary-15pc: rgba(31, 126, 214, 0.15);
    --color-primary-10pc: rgba(31, 126, 214, 0.1);
    --color-primary-5pc: rgba(31, 126, 214, 0.05);
    --color-light-primary: #b3d5f4; // Outdated
    --color-lighter-primary: #cae2f7;
    --color-light-outline: #eaebeb;
    --color-dark-grey: #4a4a4a;
    --color-grey: #9b9b9b;
    --color-light-grey: #a6b9c9; // Correct, Primary logo grey
    --color-lighter-grey: #dfe4e5;
    --color-light-blue: #f3f7fa;
    --color-bluish-purple: #efeaff;
    --color-pink: #feefe5;
    --color-pink-coral: #fbe8e8;
    --color-powder-blue: #e5f4ff;
    --color-sand: #fef7e5;
    --color-mint: #f0fae5;
    --color-white: white;
    --color-black: black;
    --color-black-15pc: rgba(0, 0, 0, 0.15);

    --border-radius: 4px;
    --border-radius-large: 8px;
    --border-radius-xlarge: 10px;

    --box-shadow-primary-small: 0 2px 4px 0 var(--color-primary-15pc);
    --box-shadow-primary-small-with-darker-glow: 0 6px 6px 2px var(--color-primary-20pc), 0 2px 4px 0 var(--color-primary-50pc);
    --box-shadow-primary-small-with-glow: 0px 4px 6px -2px var(--color-primary-15pc), 0px 2px 4px -1px var(--color-primary-5pc);
    --box-shadow-primary-large: 0 20px 60px 0 var(--color-primary-15pc);
    --box-shadow-primary-medium: 0 15px 30px 0 var(--color-primary-25pc);
    --box-shadow-primary-medium-bottom-with-glow: 0 4px 25px 0 var(--color-primary-15pc), 0 0 1px 0 var(--color-primary-10pc);
    --box-shadow-primary-medium-with-larger-darker-glow: 0 15px 30px 5px var(--color-primary-15pc), 0 5px 10px -5px var(--color-primary-20pc);
    --box-shadow-primary-3phase-small: 0 5px 30px -12px var(--color-primary), 0 5px 10px -5px var(--color-primary-20pc), 0 15px 30px 5px var(--color-primary-15pc);
    --box-shadow-primary-3phase-medium: 0 13px 30px -12px var(--color-primary), 0 13px 20px -5px var(--color-primary-20pc), 0 23px 30px 5px var(--color-primary-15pc);
    --box-shadow-lighter-primary-small: 0 2px 4px 0 var(--color-lighter-primary);
    --box-shadow-black-small: 0 2px 4px 0 var(--color-black-15pc);
    --box-shadow-black-medium: 0 10px 20px 0 var(--color-black-15pc);

    --nav-header-height: 48px;

    ${media.lg`
      --nav-header-height: 75px;
    `}

    --max-width: 1126px;

    // Spacing - used for padding and margin to auto-scale for different screen sizes

    --spacing-xxlg: 48px;
    --spacing-xlg: 48px;
    --spacing-lg: 32px;
    --spacing-md: 24px;
    --spacing-sm: 24px;
    --spacing-xsm: 16px;
    --spacing-xxsm: 8px;
    --spacing-xxxsm: 4px;

    ${media.sm`
      --spacing-xxlg: 64px;
      --spacing-xlg: 56px;
      --spacing-lg: 48px;
      --spacing-md: 32px;
      --spacing-sm: 24px;
      --spacing-xsm: 16px;
      --spacing-xxsm: 8px;
      --spacing-xxxsm: 4px;
    `}
  }

  body,
  html {
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
  }

  a {
    text-decoration: none;
  }

  hr {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  ul {
    margin-left: 21px;
  }
`
