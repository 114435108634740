import { createGlobalStyle } from 'styled-components'
import { heading1Style, heading2Style, heading3Style, heading4Style, heading5Style } from './FontAndSpacingStyles'
import { bodyFont } from './GlobalFonts'

export default createGlobalStyle`
  /* Typography.js Styles */

  /* Resets and Defaults */
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  progress,
  video {
    display: inline-block;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  progress {
    vertical-align: baseline;
  }

  [hidden],
  template {
    display: none;
  }

  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }

  a:active,
  a:hover {
    outline-width: 0;
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: inherit;
    font-weight: bolder;
  }

  dfn {
    font-style: italic;
  }

  mark {
    background-color: #ff0;
    color: #000;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  img {
    border-style: none;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  figure {
    margin: 1em 40px;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
    margin: 0;
  }

  optgroup {
    font-weight: bold;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  [type="reset"],
  [type="submit"],
  button,
  html [type="button"] {
    -webkit-appearance: button;
  }

  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring,
  button:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }

  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }

  textarea {
    overflow: auto;
  }

  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  html {
    font: 100%/1.15 "georgia", serif;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  * {
    box-sizing: inherit;
  }

  *:before {
    box-sizing: inherit;
  }

  *:after {
    box-sizing: inherit;
  }

  body {
    color: var(--color-typography-medium);
    ${({ $locale }) => bodyFont($locale)}
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }

  img {
    max-width: 100%;
    margin: 0 0 0.8625rem;
    padding: 0;
  }

  hgroup {
    margin: 0 0 0.8625rem;
    padding: 0;
  }

  ul {
    padding: 0;
    list-style-position: outside;
    list-style-image: none;
    margin: 0 0 1.725rem 0.2875rem;
  }

  ol {
    padding: 0;
    margin: 0 0 0.8625rem 1.15rem;
    list-style-position: outside;
    list-style-image: none;
  }

  dl {
    margin: 0 0 0.8625rem;
    padding: 0;
  }

  dd {
    margin: 0 0 0.8625rem;
    padding: 0;
  }

  p {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 32px 0;
  }

  figure {
    margin: 0 0 0.8625rem;
    padding: 0;
  }

  pre {
    margin: 0 0 0.8625rem;
    padding: 0;
    font-size: 0.85rem;
    line-height: 1.15rem;
  }

  table {
    margin: 0 0 0.8625rem;
    padding: 0;
    font-size: 1rem;
    line-height: 1.725rem;
    border-collapse: collapse;
    width: 100%;
  }

  fieldset {
    margin: 0 0 0.8625rem;
    padding: 0;
  }

  blockquote {
    padding: 0;
    margin: 0 1.15rem 0.8625rem;
  }

  form {
    margin: 0 0 0.8625rem;
    padding: 0;
  }

  noscript {
    margin: 0 0 0.8625rem;
    padding: 0;
  }

  iframe {
    margin: 0 0 0.8625rem;
    padding: 0;
  }

  hr {
    padding: 0;
    margin: 0 0 calc(0.8625rem - 1px);
    background: hsla(0, 0%, 0%, 0.2);
    border: none;
    height: 1px;
  }

  address {
    margin: 0 0 0.8625rem;
    padding: 0;
  }

  b {
    font-weight: bold;
  }

  strong {
    font-weight: bold;
    margin-bottom: 0;
  }

  dt {
    font-weight: bold;
  }

  th {
    font-weight: bold;
  }

  li {
    margin: 0 0 40px 0;
  }

  ol li {
    padding-left: 0;
  }

  ul li {
    padding-left: 0;
  }

  li > ol {
    margin-left: 1.15rem;
    margin-bottom: calc(0.8625rem / 2);
    margin-top: calc(0.8625rem / 2);
  }

  li > ul {
    margin-left: 1.15rem;
    margin-bottom: calc(0.8625rem / 2);
    margin-top: calc(0.8625rem / 2);
  }

  blockquote *:last-child {
    margin-bottom: 0;
  }

  li *:last-child {
    margin-bottom: 0;
  }

  p *:last-child {
    margin-bottom: 0;
  }

  li > p {
    margin-bottom: calc(0.8625rem / 2);
  }

  code {
    font-size: 0.85rem;
    line-height: 1.15rem;
  }

  kbd {
    font-size: 0.85rem;
    line-height: 1.15rem;
  }

  samp {
    font-size: 0.85rem;
    line-height: 1.15rem;
  }

  abbr {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
  }

  acronym {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
  }

  abbr[title] {
    border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
    cursor: help;
    text-decoration: none;
  }

  thead {
    text-align: left;
  }

  td,
  th {
    text-align: left;
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
    font-feature-settings: "tnum";
    -moz-font-feature-settings: "tnum";
    -ms-font-feature-settings: "tnum";
    -webkit-font-feature-settings: "tnum";
    padding-left: 0.76667rem;
    padding-right: 0.76667rem;
    padding-top: 0.575rem;
    padding-bottom: calc(0.575rem - 1px);
  }

  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }

  /* Our overrides of defaults */
  a {
    color: inherit;
  }

  input,
  option,
  textarea {
    color: #000000;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--color-typography-dark);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    text-rendering: optimizeLegibility;
    padding: 0;
  }

  h1 {
    ${heading1Style}
    margin: 0 0 1.15rem 0;
  }

  h2 {
    ${heading2Style}
    margin: 0 0 30px 0;
  }

  h3 {
    ${heading3Style}
    margin: 0 0 24px 0;
  }

  h4 {
    ${heading4Style}
    margin: 0 0 0.8625rem;
  }

  h5,
  h6 {
    ${heading5Style}
    margin: 0 0 0.8625rem;
  }
`
