exports.components = {
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/BlogAuthor.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/ContentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/Country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-cv-example-js": () => import("./../../../src/templates/CVExample.js" /* webpackChunkName: "component---src-templates-cv-example-js" */),
  "component---src-templates-cv-examples-js": () => import("./../../../src/templates/CVExamples.js" /* webpackChunkName: "component---src-templates-cv-examples-js" */),
  "component---src-templates-cv-template-js": () => import("./../../../src/templates/CVTemplate.js" /* webpackChunkName: "component---src-templates-cv-template-js" */),
  "component---src-templates-cv-templates-js": () => import("./../../../src/templates/CVTemplates.js" /* webpackChunkName: "component---src-templates-cv-templates-js" */),
  "component---src-templates-international-js": () => import("./../../../src/templates/International.js" /* webpackChunkName: "component---src-templates-international-js" */),
  "component---src-templates-knowledge-center-js": () => import("./../../../src/templates/KnowledgeCenter.js" /* webpackChunkName: "component---src-templates-knowledge-center-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pricing-js": () => import("./../../../src/templates/Pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-resume-sample-js": () => import("./../../../src/templates/ResumeSample.js" /* webpackChunkName: "component---src-templates-resume-sample-js" */),
  "component---src-templates-resume-samples-js": () => import("./../../../src/templates/ResumeSamples.js" /* webpackChunkName: "component---src-templates-resume-samples-js" */),
  "component---src-templates-resume-template-js": () => import("./../../../src/templates/ResumeTemplate.js" /* webpackChunkName: "component---src-templates-resume-template-js" */),
  "component---src-templates-resume-templates-js": () => import("./../../../src/templates/ResumeTemplates.js" /* webpackChunkName: "component---src-templates-resume-templates-js" */),
  "component---src-templates-success-stories-js": () => import("./../../../src/templates/SuccessStories.js" /* webpackChunkName: "component---src-templates-success-stories-js" */),
  "component---src-templates-success-story-js": () => import("./../../../src/templates/SuccessStory.js" /* webpackChunkName: "component---src-templates-success-story-js" */)
}

