// eslint-disable-next-line import/no-unresolved
import cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Cookie from '../assets/img/cookie.svg?url'
import PlusIcon from '../assets/svg/plus.svg'
import { useIntl } from '../utils/IntlContext'
import CTA from './CTA'

const CloseIcon = styled((props) => (
  <div {...props}>
    <PlusIcon />
  </div>
))`
  height: 22px;
  transform: rotate(45deg);
  width: 22px;

  svg {
    transform: scale(0.7);

    & > g > g {
      stroke: rgba(0, 0, 0, 0.7);
    }
  }
`

const CloseButton = styled((props) => (
  <div
    aria-label="dismiss cookie message"
    role="button"
    tabIndex="0"
    {...props}>
    <CloseIcon />
  </div>
))`
  cursor: pointer;
  opacity: 0.9;
  position: absolute;
  right: 0.8em;
  top: 0.8em;

  &:focus,
  &:hover {
    opacity: 1;
  }
`

const CookieConsentDialog = styled.div`
  background-color: var(--color-background-white);
  background-image: url(${Cookie});
  background-position: right bottom;
  background-repeat: no-repeat;
  border: 1px solid var(--color-background-light);
  border-radius: var(--border-radius-large);
  bottom: var(--spacing-xsm);
  box-shadow: var(--box-shadow-primary-large);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  left: var(--spacing-xsm);
  max-width: 449px;
  overflow: hidden;
  padding: var(--spacing-sm) 100px var(--spacing-sm) var(--spacing-sm);
  position: fixed;
  z-index: 9999;

  @media print {
    display: none;
  }

  @media screen and (max-width: 414px) and (orientation: portrait),
    screen and (max-width: 736px) and (orientation: landscape) {
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none;
  }
`

const CookieConsentMessage = styled.div`
  margin-bottom: var(--spacing-sm);
  line-height: 24px;

  a {
    text-decoration: underline;
  }
`

const CookieConsentCompliance = styled.div`
  align-content: space-between;
  align-items: center;
  display: flex;
  flex: 1 0 auto;
`

const CookieConsentDismiss = styled(CTA)`
  min-width: 128px;
`

const CookieConsent = () => {
  const [showDialog, setShowDialog] = useState(false)

  const { t } = useIntl()

  const fetchGeoIPCountry = async () => {
    // If anything goes wrong, return null and don't display the dialog
    try {
      const response = await fetch('/geoip/')
      if (!response.ok) {
        return null
      }

      const country = response.headers.get('CloudFront-Viewer-Country')
      if (!country) {
        return null
      }

      return country
    } catch {
      return null
    }
  }

  useEffect(() => {
    // Don't do anything if we're in SSR or if the user has already dismissed the dialog
    if (typeof window !== 'undefined' && cookies.get('cookieconsent_status')) {
      return
    }

    // Always display the dialog in development modes
    if (process.env.APP_ENV === 'development') {
      setShowDialog(true)
      return
    }

    const hasCookieLaw = [
      'AT',
      'BE',
      'BG',
      'HR',
      'CZ',
      'CY',
      'DK',
      'EE',
      'FI',
      'FR',
      'DE',
      'EL',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'PL',
      'PT',
      'SK',
      'ES',
      'SE',
      'GB',
      'UK',
      'GR',
      'EU',
    ]

    const showIfGeoIPCountryInList = async () => {
      const country = await fetchGeoIPCountry()
      if (country && hasCookieLaw.includes(country)) {
        setShowDialog(true)
      }
    }

    // Retrieve GeoIP and show dialog if country has a cookie law
    showIfGeoIPCountryInList()
  }, [])

  const dismissDialog = (e) => {
    e.preventDefault()
    cookies.set('cookieconsent_status', 'dismiss', { expires: 365 })
    setShowDialog(false)
  }

  if (!showDialog) {
    return null
  }

  return (
    <CookieConsentDialog
      role="dialog"
      aria-live="polite"
      aria-label="cookieconsent"
      aria-describedby="cookieconsent:desc">
      <CookieConsentMessage
        id="cookieconsent:desc"
        dangerouslySetInnerHTML={{ __html: t('cookie_message') }}
      />
      <CookieConsentCompliance>
        <CookieConsentDismiss
          aria-label="dismiss cookie message"
          tabIndex="0"
          onClick={dismissDialog}
          $primary>
          {t('cookie_got_it')}
        </CookieConsentDismiss>
      </CookieConsentCompliance>
      <CloseButton onClick={dismissDialog} />
    </CookieConsentDialog>
  )
}

export default CookieConsent
