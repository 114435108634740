import { css } from 'styled-components'

export const legacyBreakpoints = {
  tablet: '1024px',
  mobile: '650px',
}

export const breakpoints = {
  xs: 375,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}

const mediaQuery =
  (breakpoint) =>
  (...args) => css`
    @media screen and (min-width: ${breakpoints[breakpoint]}px) {
      ${css(...args)}
    }
  `
// allows using media queries in styled components:
//   ${media.sm`
//     padding: 8px;
//   `}
const media = Object.keys(breakpoints).reduce((definitions, label) => {
  definitions[label] = mediaQuery(label)
  return definitions
}, {})

export const isMobile = () => {
  let isMobile = false

  // Check if window exists for Gatsby build
  if (typeof window !== `undefined`) {
    const viewportWidth = window.innerWidth || document.body.clientWidth
    isMobile = viewportWidth < breakpoints.lg
  }

  return isMobile
}

export default media
