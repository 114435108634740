import { css } from 'styled-components'

import latin from './locales/latin'
import latinExt from './locales/latin-ext'

const defaultScript = {
  bodyFont: css`
    font-family: sans-serif;
  `,
  preloadFonts: {},
  cssFontFaces: () => null,
}

const mapLocaleToScript = (locale) => {
  switch (locale) {
    case 'de':
    case 'en-US':
    case 'es':
    case 'fr':
    case 'pt':
      return latin
    case 'tr':
      return latinExt
    default:
      return defaultScript
  }
}

export const bodyFont = (locale) => mapLocaleToScript(locale).bodyFont

// We can safely preload WOFF2 format because no browser supports preload that doesn't support WOFF2.
// These are roughly sorted in order of size of font (i.e. likelihood to be largest contentful paint)
// and above the fold/in headers, and general usage.
export const getFontFilesForLocale = (locale) => mapLocaleToScript(locale).preloadFonts

const GlobalFonts = ({ locale }) => {
  const LocaleGlobalFonts = mapLocaleToScript(locale).cssFontFaces

  return <LocaleGlobalFonts />
}

export default GlobalFonts
