import { css } from 'styled-components'

import InterBoldLatinWOFF from '@fontsource/inter/files/inter-latin-700-normal.woff'
import InterBoldLatinWOFF2 from '@fontsource/inter/files/inter-latin-700-normal.woff2'
import InterBoldLatinExtWOFF from '@fontsource/inter/files/inter-latin-ext-700-normal.woff'
import InterBoldLatinExtWOFF2 from '@fontsource/inter/files/inter-latin-ext-700-normal.woff2'

import InterNormalLatinWOFF from '@fontsource/inter/files/inter-latin-400-normal.woff'
import InterNormalLatinWOFF2 from '@fontsource/inter/files/inter-latin-400-normal.woff2'
import InterNormalLatinExtWOFF from '@fontsource/inter/files/inter-latin-ext-400-normal.woff'
import InterNormalLatinExtWOFF2 from '@fontsource/inter/files/inter-latin-ext-400-normal.woff2'

import InterVariableLatinWOFF2 from '@fontsource/inter/files/inter-latin-variable-full-normal.woff2'
import InterVariableLatinExtWOFF2 from '@fontsource/inter/files/inter-latin-ext-variable-full-normal.woff2'

// Body font - Latin - English, French, German, Portuguese, Russian, Spanish, and Turkish

export const InterBoldLatin = css`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src:
      url(${InterBoldLatinWOFF2}) format('woff2'),
      url(${InterBoldLatinWOFF}) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
`

export const InterBoldLatinExt = css`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src:
      url(${InterBoldLatinExtWOFF2}) format('woff2'),
      url(${InterBoldLatinExtWOFF}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
`

export const InterNormalLatin = css`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src:
      url(${InterNormalLatinWOFF2}) format('woff2'),
      url(${InterNormalLatinWOFF}) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
`

export const InterNormalLatinExt = css`
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src:
      url(${InterNormalLatinExtWOFF2}) format('woff2'),
      url(${InterNormalLatinExtWOFF}) format('woff');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
`

export const InterVariableLatin = css`
  @font-face {
    font-family: 'InterVariable';
    font-style: oblique 0deg 10deg;
    font-display: swap;
    font-weight: 100 900;
    src: url(${InterVariableLatinWOFF2}) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
`

export const InterVariableLatinExt = css`
  @font-face {
    font-family: 'InterVariable';
    font-style: oblique 0deg 10deg;
    font-display: swap;
    font-weight: 100 900;
    src: url(${InterVariableLatinExtWOFF2}) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
`

export { InterVariableLatinWOFF2, InterVariableLatinExtWOFF2 }
