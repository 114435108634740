import loadable from '@loadable/component'
import { IntlContext } from './utils/IntlContext'

// Ideally, we would:
// const TranslationsJson = loadable.lib((props) => import(`./translations/${props.locale}.json`))

// But this does not work with Gatsby. See: https://github.com/gregberge/loadable-components/issues/621
// So instead we import each component and map it. This is annoying, but works.
const TranslationsEnglish = loadable.lib(() => import('./translations/en-US.json'))
const TranslationsGerman = loadable.lib(() => import('./translations/de.json'))
const TranslationsFrench = loadable.lib(() => import('./translations/fr.json'))
const TranslationsChinese = loadable.lib(() => import('./translations/zh.json'))
const TranslationsRussian = loadable.lib(() => import('./translations/ru.json'))
const TranslationsPortuguese = loadable.lib(() => import('./translations/pt.json'))
const TranslationsSpanish = loadable.lib(() => import('./translations/es.json'))
const TranslationsArabic = loadable.lib(() => import('./translations/ar.json'))
const TranslationsTurkish = loadable.lib(() => import('./translations/tr.json'))

const TranslationsJson = ({ locale, children }) => {
  let TranslationsComponent
  switch (locale) {
    case 'en-US':
      TranslationsComponent = TranslationsEnglish
      break
    case 'de':
      TranslationsComponent = TranslationsGerman
      break
    case 'fr':
      TranslationsComponent = TranslationsFrench
      break
    case 'zh':
      TranslationsComponent = TranslationsChinese
      break
    case 'ru':
      TranslationsComponent = TranslationsRussian
      break
    case 'pt':
      TranslationsComponent = TranslationsPortuguese
      break
    case 'es':
      TranslationsComponent = TranslationsSpanish
      break
    case 'ar':
      TranslationsComponent = TranslationsArabic
      break
    case 'tr':
      TranslationsComponent = TranslationsTurkish
      break
    default:
      throw new Error('Invalid locale')
  }

  return <TranslationsComponent>{children}</TranslationsComponent>
}

/**
 * Exportable wrapper for testing translation `t` function
 * @param {Object.<string, string>} translations Dictionary of translation string key/value pairs
 * @param {string} locale The locale of the translation
 * @param {string} pathname Page path where the translation is being used
 * @returns {(key: string, interpolationValues: Object.<string, string>=) => string}
 */
export const t = (translations, locale, pathname) => (key, interpolationValues) => {
  let value = translations?.[key]
  if (!value) {
    // During the build process and in dev mode warn about missing translations, but don't fail.
    if (typeof window === 'undefined' || process.env.APP_ENV === 'development') {
      console.warn(`Translation key '${key}' is missing for locale '${locale}' on path '${pathname}'.`)
    }

    return null
  }

  if (interpolationValues) {
    for (const interpolationValueKey of Object.keys(interpolationValues)) {
      // We're changing to double bracketed interpolation values {{value}} in NextJS, this checks first for them and then
      // handles any existing single bracketed values {value} that remain for backward compatibility
      value = value.replaceAll(`{{${interpolationValueKey}}}`, interpolationValues[interpolationValueKey])
      value = value.replaceAll(`{${interpolationValueKey}}`, interpolationValues[interpolationValueKey])
    }
  }

  return value
}

const IntlContextProvider = ({ intl, pathname, children }) => {
  return (
    <TranslationsJson locale={intl.locale}>
      {(translations) => (
        <IntlContext.Provider
          value={{
            ...intl,
            t: t(translations, intl.locale, pathname),
          }}>
          {children}
        </IntlContext.Provider>
      )}
    </TranslationsJson>
  )
}

export default IntlContextProvider
