import styled, { css } from 'styled-components'
import Link from './Link'

const CTA = styled(Link).attrs({ role: 'button' })`
  border-radius: var(--border-radius-xlarge);
  font-weight: 600;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding: 12px 32px;
  text-align: center;

  ${({ $primaryHighlight }) =>
    $primaryHighlight &&
    css`
      color: var(--color-typography-on-primary);
      background-color: var(--color-background-primary);
      border: 1.5px solid var(--color-background-primary);
      box-shadow: var(--box-shadow-primary-medium);
      transition: all 200ms;

      &:hover {
        box-shadow: none;
        transition: all 200ms;
      }
    `}

  ${({ $primary }) =>
    $primary &&
    css`
      color: var(--color-typography-on-primary);
      background-color: var(--color-background-primary);
      border: 1.5px solid var(--color-background-primary);
    `}

  ${({ $secondary }) =>
    $secondary &&
    css`
      color: var(--color-typography-primary);
      background-color: transparent;
      border: 1.5px solid var(--color-lighter-primary);
    `}
`

export default CTA
