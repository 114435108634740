const defaultLocale = 'en-US'

const locales = [
  { identifier: 'en-US', localizedName: 'English' },
  { identifier: 'de', localizedName: 'Deutsch' },
  { identifier: 'fr', localizedName: 'Français' },
  { identifier: 'zh', localizedName: '中文' },
  { identifier: 'ru', localizedName: 'Pусский' },
  { identifier: 'pt', localizedName: 'Português' },
  { identifier: 'es', localizedName: 'Español' },
  { identifier: 'ar', localizedName: 'لعربية' },
  { identifier: 'tr', localizedName: 'Türkçe' },
]

module.exports.defaultLocale = defaultLocale
module.exports.locales = locales

module.exports = {
  defaultLocale,
  locales,
}
